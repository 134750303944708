import React from 'react';
import { PlayIcon } from './Icons';
import SignUpButton from './SignUpButton';

const listData = [
  {
    id: 1,
    content:
      'Fully customizable for up to 2 reward types- Welcome reward (one time) and Regular reward (recurring)',
  },
  { id: 2, content: 'Customer notification via emails on reward milestone earning and redemption' },
  { id: 3, content: '1 physical address for the business' },
  { id: 4, content: 'Free Clover POS integration for seamless rewards integration' },
  {
    id: 5,
    content:
      'Rewards flag on our Mobile app showing unlimited customers your business rewards availability, rules.',
  },
];

function OfferSection() {
  return (
    <div id='pricing-section' className='offer-section'>
      <div className='price-section-wrapper'>
        <div className='price-section'>
          <span className='discount-text'>Limited Time Offer Up to 50% off</span>
          <div className='offer-pricing'>
            <span className='old-price'>$180/ per month</span>
            <span className='new-offer-price'>
              <strong>$100</strong>/per month
            </span>
            <span className='new-offer-price-after'>for unlimited customers</span>
          </div>
          <div className='offer-sign-bottom-section'>
            <SignUpButton />
            <p className='offer-note'>
              *discounts available for long term contracts and multiple locations
            </p>
          </div>
        </div>
      </div>
      <div className='list-section-wrapper'>
        <div className='list-section offer-listing-section'>
          {listData?.map((item) => (
            <div key={item?.id} className='list-item'>
              <div>
                <PlayIcon />
              </div>
              <p className='list-item-content'>{item?.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OfferSection;
