import React from 'react';

const cardData = [
  {
    id: 1,
    componayLogo: './home/yoga-studio.png',
    comapnyName: 'Yoga Studio',
    alt: 'PREMA - Online Partner',
    url: 'https://www.premayogabrooklyn.com/',
  },
  {
    id: 2,
    componayLogo: './home/antique-store.png',
    comapnyName: 'Antique Store',
    alt: 'Our Online Partner - Eclectic Chic',
    url: 'https://eclecticdestinations.com/?_gl=1%2A9ssoe2%2A_ga%2ANzU4MTY1NzcuMTcyODU5MjEyOQ..%2A_ga_7J8GHRQKQW%2AMTcyODU5MjEyOS4xLjEuMTcyODU5MzE3OS4wLjAuMA',
  },
  {
    id: 3,
    componayLogo: './home/restaurant.png',
    comapnyName: 'Restaurant',
    alt: 'Matthews - Brand Logo',
    url: 'https://www.lamottapizzeria.com/',
  },
  {
    id: 4,
    componayLogo: './home/cleaning.png',
    comapnyName: 'Cleaning',
    alt: 'Blackwater - Our Online Partner',
    url: 'https://blackwaterpowerwashing.com/',
  },
  {
    id: 5,
    componayLogo: './home/bakery.png',
    comapnyName: 'Bakery',
    alt: 'Daisy Hill - Brand Partner',
    url: 'https://www.facebook.com/daisyhilldelights/?checkpoint_src=any',
  },
  {
    id: 6,
    componayLogo: './home/pest-services.png',
    comapnyName: 'Pest Services',
    alt: 'D & R - Online Brand Partner',
    url: 'https://www.dandrpestcontrol.com/',
  },
];

function StoreSection() {
  return (
    <div className='stores-section'>
      <h2 className='section-title'>A few Mom n Pop Shops with us</h2>
      <div className='store-crads'>
        {cardData?.map((company) => (
          <a
            key={company?.id}
            className='company-card'
            href={company?.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='company-logo'>
              <img src={company?.componayLogo} alt={company?.alt} />
            </div>
            <p className='company-name'>{company?.comapnyName}</p>
          </a>
        ))}
      </div>
    </div>
  );
}

export default StoreSection;
