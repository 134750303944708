import React from 'react';

const cardData = [
  {
    id: 1,
    imgUrl: './home/systemSection/sc1.png',
    alt: 'Register Business & Get Rewards ',
    content:
      'Establish conditions for Welcome and Regular rewards. The Welcome reward should be a delightful, easily achievable treat available just once to make a strong first impression. Regular rewards should require more effort but be worth it, motivating clients to stay engaged and return!',
  },
  {
    id: 2,
    imgUrl: './home/systemSection/sc2.png',
    alt: 'Earn Rewards with Mom N Pop App',
    content:
      'When your customers visit, they can simply scan the QR code from the Mom n Pop Hub app to earn points. This not only brings them closer to exciting rewards but also encourages them to return to your business time and time again.',
  },
];

function SystemWorkSection() {
  return (
    <div id='reward-system'>
      <h3 className='system-section-header'>How Mom n Pop Hub's rewards system works</h3>
      <div className='system-section'>
        <div className='content-section'>
          <div className='system-cards'>
            {cardData?.map((card) => (
              <div className='system-card' key={card?.id}>
                <img src={card?.imgUrl} alt={card?.alt} />
                <p className='card-content'>{card?.content}</p>
              </div>
            ))}
          </div>
          <div className='download-section desktop-download-section'>
            <div className='download-content'>
              <h4>Download the app and share your business listing with new and existing customers.</h4>
              <p>
                Exploring profiles of other businesses and gain insight into how other small
                businesses interact with their customers{' '}
              </p>
            </div>
            <div className='download-btns'>
              <a
                href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='./home/apple-store-badge.png' className='' alt='Listing App for Iphone Users' />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.momnpophub'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='./home/google-play-badge.png' className='' alt='Listing App for Android Users' />
              </a>
            </div>
          </div>
        </div>
        <div className='image-content-section'>
          <img src='./home/system-work.png' alt='Online App for Small Business Listing' />
          <p className='content'>
            Once customers accumulate enough points, they can visit your store and scan a special
            code to redeem their reward when placing an order. When your salesperson scans the code,
            the discount is automatically applied to the total and reflected in the cash register
            system, ensuring a seamless checkout experience.
          </p>
        </div>
        <div className='download-section mobile-download-section'>
            <div className='download-content'>
              <h4>Download the app and share your business listing with new and existing customers.</h4>
              <p>
                Exploring profiles of other businesses and gain insight into how other small
                businesses interact with their customers{' '}
              </p>
            </div>
            <div className='download-btns'>
              <a
                href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='./home/apple-store-badge.png' className='' alt='Listing App for Iphone Users' />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.momnpophub'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='./home/google-play-badge.png' className='' alt='Listing App for Android Users' />
              </a>
            </div>
          </div>
      </div>
    </div>
  );
}

export default SystemWorkSection;
