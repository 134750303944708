import React from 'react';
import { Helmet } from 'react-helmet';

import GrowSection from '../../components/newHome/GrowSection';
import WayToSignUpSection from '../../components/newHome/WayToSignUpSection';
import RewardSystemSection from '../../components/newHome/RewardSystemSection';
import RegisterBussinessSection from '../../components/newHome/RegisterBussinessSection';
import SystemWorkSection from '../../components/newHome/SystemWorkSection';
import OfferSection from '../../components/newHome/OfferSection';
import BenefitSection from '../../components/newHome/BenefitSection';
import StoreSection from '../../components/newHome/StoreSection';
import JoinUsSection from '../../components/newHome/JoinUsSection';

import './NewHome.css';

function NewHome(props) {
  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Free Local Listing App | List Your Business Online & Earn Rewards!</title>
        <meta
          name='description'
          content='List your small business for free in our local listing app! Let your customers be more loyal by earning rewards. Join us today!'
        />
        <meta name='description' content='Adversite Your bussiness with Mom n Pop Hub'></meta>
        <meta
          name='keywords'
          content='Rewards for small businesses,Register Your business online,Small Business Grow Customer Loyalty, Free Business Directory, free business directory usa,Add your Business for Free, Business Listing App, Local Business Listing App, Local Listing App, List Small Business Online '
        ></meta>
        <meta name='publisher' content='Mom n Pop Hub'></meta>
        <link rel='canonical' href={process.env.RAZZLE_RUNTIME_FRONTBASE_URL} />
      </Helmet>
      <>
        <div className='container home-container'>
          <GrowSection />
          <StoreSection />
          <WayToSignUpSection />
          <RewardSystemSection />
        </div>
        <RegisterBussinessSection />
        <div className='container home-container'>
          <SystemWorkSection />
          <OfferSection />
        </div>
        <BenefitSection {...props} />
        <div className='container home-container'>
          <JoinUsSection />
        </div>
      </>
    </>
  );
}

export default NewHome;
