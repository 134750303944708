import React from 'react';
import './Sections.css';
import './SectionMediaQueries.css';

function GrowSection() {
  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 500);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='grow-section-wrapper'>
      <div className='content-section'>
        <div>
          <h1>Grow Customer Loyalty</h1>
          <p className='grow-text'>
            Engage your customers with a mobile app based rewards program to boost your small
            business customer loyalty and gain a competitive edge.
          </p>
          <div className='button-wrapper'>
            <a href='#reward-system' type='button' className='learn-how-btn'>
              Learn how
            </a>
            <a type='button' className='sign-up-btn' href='/free-business-invitation'>
              Free sign up
            </a>
          </div>
        </div>
        <div className='stats-section'>
          <div className='stat-card'>
            <h2>40%</h2>
            <p>Upto 40% increase in customer loyalty.</p>
          </div>
          <div className='stat-card'>
            <h2>+1200</h2>
            <p>small businesses that choose us</p>
          </div>
        </div>
      </div>
      <div className='image-section'>
        {isMobile ? (
          <img
            className='mobile-image'
            src='./home/grow-section-mobile.png'
            width={344}
            alt='List Your Small Business Online'
            // loading='lazy'
          />
        ) : (
          <img
            className='general-image'
            src='./home/grow-section.png'
            width={694}
            alt='List Your Small Business Online'
            loading='lazy'
          />
        )}
      </div>
    </div>
  );
}

export default GrowSection;
