import React from 'react';
import { LensIcon, QuotesIcon } from './Icons';
import AboutSearch from '../../pages/about/components/about_search';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const reviewCardData = [
  {
    id: 1,
    name: 'Brianna, GA',
    review:
      'Love helping small businesses 🙂 great app, allows me to find small businesses around me within a tap.',
  },
  { id: 2, name: 'John, CA', review: 'Love this app to find small businesses nearby!' },
];

function BenefitSection(props) {
  const history = useHistory();
  return (
    <div className='benefit-section'>
      <div className='title-section'>
        <h2 className='benefit-title'>Benefits Outside Rewards Program</h2>
        <p className='benefit-sub-title'>
          We have plenty of benefits you can enjoy for signing up for free:
        </p>
      </div>
      <div className='main-section'>
        <div className='business-directory'>
          <h4>Our Small Business Directory</h4>
          <p>
            Thousands of small business customers visit our website and mobile app to search for
            small businesses like yours. Creating a free account takes less than 30 seconds and
            gives you visibility on the small business directory instantly.
          </p>
          {/* <h4>Search for a small business around you</h4> */}
          {/* <div className='search-input-btn'>
            <div className='search-input-wrapper'>
              <LensIcon />
              <input className='search-input' placeholder='Please type the business name' />
            </div>
            <button className='search-btn'>Search</button>
          </div> */}
          <AboutSearch
            history={history}
            setSelectedProfileId={props.setSelectedProfileId}
            setSelectedDealId={props.setSelectedDealId}
          />
        </div>
        <div className='customer-review'>
          <h4>Customer Quote from App Reviews:</h4>
          <p className='customer-review-text'>
            Thousands of small business customers visit our website and mobile app to search for
            small businesses like yours. Creating a free account takes less than 30 seconds and
            gives you visibility on the small business directory instantly.
          </p>
          <div className='review-cards-container'>
            {reviewCardData?.map((data) => (
              <div className='review-card' key={data?.id}>
                <QuotesIcon />
                <p className='customer-name'>{data?.name}</p>
                <p className='review-text'>{data?.review}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitSection;
