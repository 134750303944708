import React from 'react';
import { PlayIcon } from './Icons';
import SignUpButton from './SignUpButton';

const listContent = [
  {
    id: 1,
    content: (
      <>
        Your business is live on mobile app small business directory instantly.
        {/* <ul>
          <li>option 1</li>
          <li>option 2</li>
        </ul> */}
      </>
    ),
  },
  {
    id: 2,
    content:
      'Send personalized emails to your customers inviting them to  your listing on Mom n Pop Hub and favorite your business. ',
  },
  {
    id: 3,
    content:
      'When you are ready sign up for our rewards program to honor your loyal customers and attract new customers. ',
  },
];

function RegisterBussinessSection() {
  return (
    <div className='register-section'>
      <div className='content-section'>
        <div>
          <h1 className='register-title'>
            Register your business in our app today—completely free!
          </h1>
          {listContent?.map((list) => (
            <div key={list?.id} className='list-item'>
              <div>
                <PlayIcon />
              </div>
              <p className='list-item-content'>{list.content}</p>
            </div>
          ))}
        </div>
        <SignUpButton />
      </div>
      <div className='image-section'>
        <img src='./home/registerSection/Img.png' alt='Online Business Listing App ' />
      </div>
    </div>
  );
}

export default RegisterBussinessSection;
