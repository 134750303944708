import './Header.css';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';

import DefaultImage from '../../assets/images/default.png';
import Logo from '../../assets/images/logo/momnpophub-logo.svg';
import UserContext from '../../contexts/userContext';
import LoginModal from '../login-modal';

const useStyles = makeStyles({
  list: {
    width: 350,
  },
  fullList: {
    width: 'auto',
  },
});

const Header = ({ isLoggedIn, logout, where, isUserEditor }) => {
  useEffect(() => {
    if (where === 'Home') {
      setInHome(1);
    } else {
      setInHome(0);
    }
    if (where === 'Bus') {
      setInBus(1);
    } else {
      setInBus(0);
    }
    if (where === 'Log') {
      setInLog(1);
    } else {
      setInLog(0);
    }
    if (where === 'FAQ') {
      setInFAQ(1);
    } else {
      setInFAQ(0);
    }
    if (where === 'Business') {
      setInBusiness(1);
    } else {
      setInBusiness(0);
    }
  }, [where]);

  const history = useHistory();
  const [inHome, setInHome] = React.useState(1);
  const [inBus, setInBus] = React.useState(0);
  const [inLog, setInLog] = React.useState(0);
  const [inFAQ, setInFAQ] = React.useState(0);
  const [inBusiness, setInBusiness] = React.useState(0);
  const [selectedStore, setSelectedStore] = React.useState(null);
  const [isOpenedDropDown, setIsOpenedDropDown] = React.useState(false);
  const classes = useStyles();
  const [state, setState] = React.useState({ right: false });
  const [open, setOpen] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { userData, setUserData } = useContext(UserContext);

  let location = useLocation().pathname,
    headerClass = location !== '/home' ? 'logout-header' : null;
  // console.log(location);
  const handleClick = () => {
    setOpen(!open);
  };
  const authtok = Cookies.get('authtoken');
  const uname = Cookies.get('uname');

  isLoggedIn = location = '/business-home' && authtok ? true : isLoggedIn;
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toogleBusinessDropDown = () => {
    setIsOpenedDropDown(!isOpenedDropDown);
  };

  const onLogout = () => {
    Cookies.remove('authtoken');
    Cookies.remove('uname');
    setState({ right: false });
    logout(false);
    history.push('/login');
    setUserData({ isLogin: false });
    window.location.reload();
  };
  const navigationLinks = [
    {
      title: 'About Us',
      path: '',
    },
    {
      title: 'Business Categories',
      path: 'business-category',
    },
    {
      title: 'Add Your Business for FREE',
      path: 'free-business-invitation',
    },
    {
      title: 'FAQ',
      path: 'faq',
    },
    {
      title: 'Blog',
      path: 'blog',
    },
  ].map((link, index) => {
    return (
      <React.Fragment key={index + link.title}>
        <Divider />
        <ListItem
          button
          className='justify-content-center'
          selected={location === '/customer-business-app' + link.path}
          classes={{ selected: 'active' }}
          onClick={() => {
            setState({ right: false });
            history.push(`/${link.path}`);
          }}
        >
          {link.title}
        </ListItem>
      </React.Fragment>
    );
  });

  const list = () => (
    <div role='presentation'>
      {!isLoggedIn && (
        <List className='logout-header__ite gin-toggle text-decoration-none'>
          <ListItem
            className='d-flex justify-content-center align-items-center'
            onClick={() => setShowLogin(true)}
          >
            {' '}
            <i className='icon-user-active' />
            Business Login
          </ListItem>
          {navigationLinks}
        </List>
      )}
      {isLoggedIn && (
        <List>
          <ListItem button onClick={handleClick} className='justify-content-center'>
            <div className='d-flex align-items-center menu-user'>
              <span className='menu-user__name text-dark'>{uname}</span>
              <figure className='rounded-circle overflow-hidden m-0 menu-user__image bg-white'>
                <img
                  className='thumbnail-image img-fluid w-100 h-100'
                  src={DefaultImage}
                  alt='User Image Mom n Pop Hub'
                />
              </figure>
            </div>
          </ListItem>
          <ListItem>
            <Divider />
            <ListItem button className={classes.nested} onClick={onLogout}>
              Log Out
            </ListItem>
          </ListItem>
        </List>
      )}
    </div>
  );
  return (
    <div className={headerClass}>
      {location !== '/mobile-reset' && (
        <React.Fragment>
          <Navbar
            className='header-menu col-xl-11 mx-auto d-flex align-content-center justify-content-between'
            expand='xl'
          >
            <Navbar.Brand as={Link} to='/'>
              <img src={Logo} alt='Mom n Pop Hub Logo' />
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls='basic-navbar-nav'
              className='text-white mobile-navbar-toggler'
              onClick={toggleDrawer('right', true)}
            >
              <svg
                width='40'
                height='40'
                viewBox='0 0 40 40'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <line
                  x1='12.75'
                  y1='13.25'
                  x2='27.25'
                  y2='13.25'
                  stroke='#878787'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                />
                <line
                  x1='12.75'
                  y1='19.25'
                  x2='27.25'
                  y2='19.25'
                  stroke='#878787'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                />
                <line
                  x1='12.75'
                  y1='25.25'
                  x2='27.25'
                  y2='25.25'
                  stroke='#878787'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                />
                <rect x='0.5' y='0.5' width='39' height='39' rx='3.5' stroke='#878787' />
              </svg>
            </Navbar.Toggle>
            {!isLoggedIn && (
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='mr-auto row d-flex justify-content-around'>
                  <Nav.Link
                    className={`header-menu__item d-flex align-items-center  p-0 ${
                      useLocation().pathname === '/' ? 'active' : ''
                    } `}
                    style={{
                      marginLeft: '0px',
                    }}
                    onClick={() => {
                      history.push('/');
                      setSelectedStore(null);
                    }}
                  >
                    About Us
                  </Nav.Link>

                  <Nav.Link
                    className={`header-menu__item d-flex  p-0 ${
                      location === '/business-category' ? 'active' : ''
                    } `}
                    style={{ marginLeft: '0px' }}
                  >
                    <div className='dropdown'>
                      <button
                        className='btn btn-secondary dropdown-toggle'
                        type='button'
                        id='dropdownMenuButton'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <span
                          className={`${
                            useLocation().pathname === '/business-category/physical-store' ||
                            useLocation().pathname === '/business-category/online-store'
                              ? 'active'
                              : ''
                          }`}
                        >
                          Business Categories
                        </span>
                      </button>
                      <div
                        className={isOpenedDropDown ? 'dropdown-menu show' : 'dropdown-menu '}
                        aria-labelledby='dropdownMenuButton'
                      >
                        <a
                          href='#'
                          className={
                            selectedStore === 0 ? 'dropdown-item active' : 'dropdown-item '
                          }
                          onClick={() => {
                            setSelectedStore(0);
                            history.push('/business-category/physical-store');
                          }}
                        >
                          Physical store
                        </a>
                        <a
                          href='#'
                          className={
                            selectedStore === 1 ? 'dropdown-item active' : 'dropdown-item '
                          }
                          onClick={() => {
                            setSelectedStore(1);
                            history.push('/business-category/online-store');
                          }}
                        >
                          Online store
                        </a>
                      </div>
                    </div>
                  </Nav.Link>

                  <Nav.Link
                    className={`header-menu__item d-flex align-items-center p-0 ${
                      useLocation().pathname === '/free-business-invitation' ? 'active' : ''
                    } `}
                    style={{ marginLeft: '0px' }}
                    onClick={() => {
                      history.push('/free-business-invitation');
                      setSelectedStore(null);
                    }}
                  >
                    Add Your Business for FREE
                  </Nav.Link>
                  <Nav.Link
                    className={`header-menu__item d-flex align-items-center p-0 ${
                      useLocation().pathname === '/faq' ? 'active' : ''
                    } `}
                    style={{ marginLeft: '0px' }}
                    onClick={() => {
                      history.push('/faq');
                      setSelectedStore(null);
                    }}
                  >
                    FAQ{' '}
                  </Nav.Link>
                  <Nav.Link
                    className={`header-menu__item d-flex align-items-center p-0 ${
                      useLocation().pathname === '/blog' ? 'active' : ''
                    } `}
                    style={{ marginLeft: '0px' }}
                    onClick={() => {
                      history.push('/blog');
                      setSelectedStore(null);
                    }}
                  >
                    Blog
                  </Nav.Link>
                  <Nav.Link
                    className={`header-menu__item d-flex align-items-center  p-0 ${
                      useLocation().pathname === '/login' ? 'active' : ''
                    } `}
                    onClick={() => {
                      history.push('/login');
                      setSelectedStore(null);
                    }}
                  >
                    <i className='icon-user-active mr-2' />
                    Business Login
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            )}
            {isLoggedIn && (
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='mr-auto row d-flex justify-content-around'>
                  <Nav.Link
                    className={`header-menu__item d-flex align-items-center p-0 ${
                      location === '/business-home' ? 'active' : ''
                    }`}
                    onClick={() => history.push('/business-home')}
                  >
                    Business Home
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            )}
            {isLoggedIn && (
              <NavDropdown
                id='basic-nav-dropdown'
                className='header-menu__item header-dropdown'
                title={
                  <div className='d-flex align-items-center menu-user'>
                    <span className='menu-user__name'>{uname}</span>
                    <figure className='rounded-circle overflow-hidden m-0 menu-user__image bg-white'>
                      <img
                        className='thumbnail-image img-fluid w-100 h-100'
                        src={DefaultImage}
                        alt='User Image Mom n Pop Hub'
                      />
                    </figure>
                  </div>
                }
              >
                {isUserEditor && (
                  <Link to='/blog-admin-panel'>
                    <NavDropdown.Item href='#action/3.1'>Admin Panel Blog</NavDropdown.Item>
                  </Link>
                )}
                <Link to='/home'>
                  <NavDropdown.Item href='#action/3.1' onClick={onLogout}>
                    Log Out
                  </NavDropdown.Item>
                </Link>
              </NavDropdown>
            )}
          </Navbar>
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false).bind(this)}
          >
            {list()}
          </Drawer>
          <LoginModal
            show={showLogin}
            disabled={isDisabled}
            handleShow={() => setShowLogin(true)}
            handleClose={() => setShowLogin(false)}
            handleLogin={() => logout(true)}
            setDisabled={(disabled) => setIsDisabled(disabled)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Header;
