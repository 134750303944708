import React from 'react';
import SignUpButton from './SignUpButton';

function JoinUsSection() {
  return (
    <div className='join-contact-section'>
      <div className='join-us-section'>
        <h4>Join us</h4>
        <p>
          Sign up for free to create your profile and access our rewards system for your customers,
          or book a demo to see how it all works in action.{' '}
        </p>
        <h5>Take the first step to increasing customer loyalty and sales today!</h5>
        <div className='button-wrapper'>
          <SignUpButton />
          <a
            type='button'
            className='demo-btn'
            href='https://calendly.com/momnpophub/rewards-demo'
            target='_blank'
            rel='noopener noreferrer'
          >
            Book a demo
          </a>
        </div>
      </div>
      <div className='contact-us-section'>
        <img src='/momnp.png' alt='mom&pop' width={104} height={88} />
        <h4>Contact Us</h4>
        <p>Please feel free to send your questions to </p>
        <a href='mailto:contact@momnpophub.com'>contact@momnpophub.com.</a>
        <p>We love helping small businesses!</p>
        <h5>Don't forget to download our app </h5>
        <div className='download-btns'>
          <a
            href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src='./home/apple-store-badge.png' className='' alt='Listing App for Iphone Users' />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=com.momnpophub'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src='./home/google-play-badge.png' className='' alt='Listing App for Android Users' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default JoinUsSection;
