import React from 'react';
import './Sections.css';

import { GroupSvg, Heart, LeaderBoard, MobileFriendly } from './Icons';

const rewardCardsData = [
  {
    logo: <GroupSvg />,
    title: 'Acquire & Retain Customers',
    content:
      'Our unique 2 tier reward system helps you with both customer acquisition & retention within one system. You can setup the rewards program within 10 minutes. ',
  },
  {
    logo: <MobileFriendly />,
    title: 'Mobile App Based Rewards',
    content:
      'Our mobile app based rewards system will raise your customer loyalty by 3x compared to POS, web or SMS based rewards system only. Your first customers can earn rewards within seconds on our app.',
  },
  {
    logo: <Heart />,
    title: 'Grow Brand Loyalty ',
    content:
      'Customers want to grow their loyalty with small businesses & a seamless mobile first UX experience is the best way to grow this loyalty. ',
  },
  {
    logo: <LeaderBoard />,
    title: 'Competitive Advantage',
    content:
      'Having Mom n Pop Hub rewards system will make you business stand out from competition with fraction of a cost, seamless integration & world class reporting. ',
  },
];

function RewardSystemSection() {
  return (
    <div className='reward-section'>
      <div className='reward-image'>
        <img key='desktop' className='desktop-reward' src='./home/rewardSection/reward.png' alt='Mom N Pop - Online Business Listing App' />
        <img key='mobile' className='mobile-reward' src='./home/rewardSection/reward-mobile.png' alt='Mom N Pop - Online Business Listing App' />
      </div>
      <div className='reward-cards'>
        {rewardCardsData?.map((card) => (
          <div key={card?.title} className='reward-card'>
            <div className='reward-logo'>{card?.logo}</div>
            <div>
              <p className='reward-card-title'>{card?.title}</p>
              <p className='reward-card-content'>{card?.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RewardSystemSection;
