import './App.css';

import Cookies from 'js-cookie';
import React, { useCallback, useContext, useEffect, useParams, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { callApi } from '../api/API';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import shareBussiness from '../components/share-bussiness/shareBussiness';
import ShareDeals from '../components/share-deals';
import DealContext from '../contexts/dealContext';
import UserContext from '../contexts/userContext';
import About from '../pages/about';
import BusProfile from '../pages/bus_profile/bus_profile';
import Business from '../pages/business';
import Categories from '../pages/business-category';
import Categorylist from '../pages/business-category/components/categorylist';
import PhysicalStoreSubcategory from '../pages/business-category/components/physical_store_subcategory';
import categorydata from '../pages/business/components/categorydata';
import onlinecategorydata from '../pages/business/components/onlinecategorydata';
import BusinessHome from '../pages/bussines-home/Business-home';
import CustomerBusinessApp from '../pages/customer-business-app';
import Ddetail from '../pages/d_detail/d_detail';
import DealDetails from '../pages/deal-details/Deal-details';
import FAQ from '../pages/faq';
import Htmlsitemap from '../pages/htmlsitemap/htmlsitemap';
import LoginPage from '../pages/login';
import MobileResetPassword from '../pages/mobile-reset-page';
import NotFoundPage from '../pages/notFound/notfound';
import Privacy from '../pages/privacy/privacy';
import RecoverPassword from '../pages/recover-password';
import ResetPassword from '../pages/reset-password';
import SignUp from '../pages/sign-up';
import SignupSuccess from '../pages/signup-success';
import StoreDescList from '../pages/store_description/';
import Temp from '../pages/temp';
import Term from '../pages/term/term';
import Unsubscribe from '../pages/unsubscribe/unsubscribe';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnAuthenticatedRoute from './UnAuthenticatedRoute';
import withDeepLink from '../hoc/withDeepLink';
import Blog from '../pages/blog';
import ArticleForm from '../pages/blog/ArticleForm';
import ArticleDetails from '../pages/blog/ArticleDetails';
import AdminPanelBlog from '../pages/blog-admin-panel';
import EditorArticle from '../pages/blog-admin-panel/editor';
import { userInfo } from '../api/blog';
import DeleteUser from '../pages/delete-user/DeleteUser';
import { Toaster } from 'react-hot-toast';
import NewHome from '../pages/newHome/NewHome';

//import data from "../pages/business/components/categorydata";
// import { createBrowserHistory } from "history";
if (typeof window !== 'undefined') {
  ReactGA.initialize('G-LNTFNXSS3X');
}

const App = ({ match, location, history }) => {
  if (location.pathname === '/stores/physical') {
    let data = categorydata;
  }

  if (location.pathname === '/stores/online') {
    let data = onlinecategorydata;
  }

  if (typeof window !== 'undefined') {
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [window.location.pathname + window.location.search]);
  }
  const [userData, setUserData] = useState({
    token: Cookies.get('authtoken'),
    id: Cookies.get('id'),
    uname: '',
    isLogin: false,
    type: undefined,
    btype: Cookies.get('btype'),
  });
  const [demo, setDemo] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(false);
  const [dealData, setDealData] = useState({});
  const [where, setWhere] = useState('');

  const [userEditor, setUserEditor] = useState(false);

  const authToken = Cookies.get('authtoken');
  useEffect(async () => {
    if (authToken) {
      try {
        const usId = Cookies.get('id');
        const fetchUserInfo = await userInfo(usId);
        await setUserEditor(fetchUserInfo?.user_editor);
      } catch (err) {
        setUserEditor(false);
      }
    }
  }, [authToken]);

  function setNewWhere(val) {
    setWhere(val);
  }

  const getIntData = useCallback(async () => {
    const token = Cookies.get('authtoken');
    const uname = Cookies.get('uname') || '';
    // console.log("token=" + token);
    if (!token) {
      try {
        console.log("here------------")
        const checktoken = await callApi(
          '/users/checktoken',
          'POST',
          {
            token: token,
          },
          'Bearer ' + userData.token,
        );
        if (checktoken && checktoken.token) {
          const { token, udata } = checktoken;
          const { email, id, btype } = udata;
          setUserData({
            ...userData,
            isLogin: true,
            token: token,
            uname: email,
            id: id,
            btype: btype,
          });
          Cookies.set('authtoken', token);
          Cookies.set('uname', email);
        } else {
          // console.log(checktoken);
          setUserData({
            ...userData,
            isLogin: false,
            token: null,
            uname: null,
          });
          history.push(location.pathname);
        }
      } catch (err) {
        setUserData({
          ...userData,
          isLogin: false,
          token: null,
        });
        Cookies.remove('authtoken');
        Cookies.remove('uname');
        history.push(location.pathname);
      }
    } else {
      if (uname === '') {
        setUserData({
          ...userData,
          isLogin: false,
          token: null,
        });
        Cookies.remove('authtoken');
        Cookies.remove('uname');
        history.push(location.pathname);
      } else {
        setUserData({
          ...userData,
          isLogin: true,
          token: token,
          uname: uname,
        });
      }
    }
  }, [history, userData]);

  useEffect(() => {
    getIntData();
  }, []);

  const [isLoggedIn, toggleLogin] = useState(false);
  const loginAndRedirect = (loginSuccess) => {
    toggleLogin(loginSuccess);
    if (loginSuccess) {
      history.push('/business-home');
    } else {
      history.push('/login');
    }
  };

  let mainClass = `${location.pathname === '/' ? 'home-main-block' : 'container'}`;

  switch (location.pathname) {
    case '/business-home':
      mainClass = 'main-container';
      break;
    case '/profile':
      mainClass = 'main-container';
      break;
    case '/ddetail':
      mainClass = 'main-container';
      break;
    case location.pathname.match('/businessinfo/profile')?.input:
      mainClass = 'main-container';
      break;
    case location.pathname.match('/businessinfo/deal')?.input:
      mainClass = 'main-container';
      break;
    default:
      mainClass = '';
  }
  //const {categoryname, subcategoryname} = useParams();
  // bof ashok
  // pulling all physical categories
  let tempcat = [];
  let tempsubcat = [];
  categorydata.category.map((cats, index) => {
    tempcat.push(cats.machine_name);
    cats.subcategory.map((subcat) => {
      tempsubcat.push(subcat.machine_name);
    });
  });
  // pulling all online categories

  let onlineTempcat = [];
  let onlineTempsubcat = [];
  onlinecategorydata.category.map((onlinecats, index) => {
    onlineTempcat.push(onlinecats.machine_name);
    onlinecats.subcategory.map((subcat) => {
      onlineTempsubcat.push(subcat.machine_name);
    });
  });

  let categoryArry = [...tempcat, ...tempsubcat];
  let onlinecatArry = [...onlineTempcat, ...onlineTempsubcat];
  let AllCategories = [...categoryArry, ...onlinecatArry];

  const staticpath = [
    'customer-business-app',
    'free-business-invitation',
    'login',
    'business-category',
    'businessinfo',
    'business-home',
    'sitemap',
    'term',
    'physical',
    'online',
    'stores',
    'faq',
    'blog',
    'share-businessdetails',
    'privacy',
    ...AllCategories,
  ];

  const routepath = [
    'customer-business-app',
    'free-business-invitation',
    'login',
    'business-category',
    'businessinfo',
    'business-home',
    'sitemap',
    'term',
    'physical',
    'online',
    'stores',
    'privacy',
    'faq',
    'blog',
    'share-businessdetails',
  ];

  let pathcheck = location.pathname.split('/');

  //console.log(pathcheck + ' ============outside of if=======> '+pathcheck.length);
  let idofdb;
  try {
    if (localStorage.getItem('id')) {
      const validId = () => {
        let aid = localStorage.getItem('id');
        if (aid.match(/^[0-9a-fA-F]{24}$/)) {
          return true;
        } else {
          return false;
        }
      };
      idofdb = validId();
    }
  } catch (e) {}

  // try{
  //  console.log(localStorage.getItem("id") + ' Local storeage');
  // }catch(e){}
  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@type': 'Organization',
            url: process.env.RAZZLE_RUNTIME_FRONTBASE_URL,
            logo: `${process.env.RAZZLE_RUNTIME_FRONTBASE_URL}/static/media/momnpophub-logo.1f15802f.svg`,
            legalName: 'Mom n Pop Hub',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '7896352410',
              contactType: 'customer service',
              availableLanguage: 'English',
            },
            '@context': 'http://schema.org',
          })}
        </script>
      </Helmet>
      <Toaster />
      {/* add main-container container class for business pages */}
      <UserContext.Provider value={{ userData, setUserData, demo, setDemo }}>
        <DealContext.Provider value={{ dealData, setDealData }}>
          <main className={`min-vh-100 ${mainClass}`}>
            <Header
              isLoggedIn={userData.isLogin}
              logout={loginAndRedirect}
              where={where}
              isUserEditor={userEditor}
            />
            <Switch>
              <UnAuthenticatedRoute
                exact
                path='/homeV1'
                render={(props) => (
                  <About
                    {...props}
                    setWhere={setNewWhere}
                    where={where}
                    setSelectedProfileId={setSelectedProfileId}
                    setSelectedDealId={setSelectedDealId}
                  />
                )}
              />
              <UnAuthenticatedRoute
                exact
                path='/homeV1'
                render={(props) => (
                  <About
                    {...props}
                    setWhere={setNewWhere}
                    where={where}
                    setSelectedProfileId={setSelectedProfileId}
                    setSelectedDealId={setSelectedDealId}
                  />
                )}
              />
              <UnAuthenticatedRoute
                exact
                path='/'
                render={(props) => (
                  <NewHome
                    {...props}
                    setSelectedProfileId={setSelectedProfileId}
                    setSelectedDealId={setSelectedDealId}
                  />
                )}
              />
              <UnAuthenticatedRoute
                exact
                path='//'
                render={(props) => (
                  <NewHome
                    {...props}
                    setSelectedProfileId={setSelectedProfileId}
                    setSelectedDealId={setSelectedDealId}
                  />
                )}
              />
              {/* bof ashok */}

              {/*
              {(idofdb && localStorage.getItem("page")) ||
              (!staticpath.includes(pathcheck[1]) &&
                !routepath.includes(pathcheck[1])) ? (
                <UnAuthenticatedRoute
                  // path="/business"
                  component={shareBussiness}
                />
              ) : null}

              {idofdb && localStorage.getItem("page") ? (
                <UnAuthenticatedRoute component={shareBussiness} />
              ) : null} */}

              <UnAuthenticatedRoute
                exact
                path='/business-category'
                render={(props) => <Categories {...props} where={where} setWhere={setNewWhere} />}
              />

              <UnAuthenticatedRoute
                exact
                path='/business-category/:type?'
                render={(props) => <Categorylist {...props} where={where} setWhere={setNewWhere} />}
              />

              <UnAuthenticatedRoute
                exact
                path='/business-category/:type?/:subcategory?'
                render={(props) => <PhysicalStoreSubcategory {...props} cats={pathcheck[1]} />}
              />

              <UnAuthenticatedRoute
                exact
                path='/business-category/:type?/:categoryname/:subcategoryname?'
                component={StoreDescList}
              />

              <UnAuthenticatedRoute
                exact
                path='/business-category/:type?/:categoryname/:subcategoryname?/:state?/:city?/:bname?'
                component={shareBussiness}
              />

              {/* eof ashok */}
              <UnAuthenticatedRoute
                exact
                path='/customer-business-app'
                render={(props) => (
                  <CustomerBusinessApp {...props} setWhere={setNewWhere} where={where} />
                )}
              />
              <UnAuthenticatedRoute
                exact
                path='/login'
                render={(props) => <LoginPage {...props} setWhere={setNewWhere} />}
              />
              <UnAuthenticatedRoute exact path='/mobile-reset' component={MobileResetPassword} />
              <UnAuthenticatedRoute
                exact
                path='/free-business-invitation'
                render={(props) => (
                  <Business
                    {...props}
                    setWhere={setNewWhere}
                    where={where}
                    setSelectedProfileId={setSelectedProfileId}
                    setSelectedDealId={setSelectedDealId}
                  />
                )}
              />
              <UnAuthenticatedRoute exact path='/recover' component={RecoverPassword} />
              <UnAuthenticatedRoute exact path='/reset-password' component={ResetPassword} />
              <UnAuthenticatedRoute exact path='/unsubscribe' component={Unsubscribe} />
              <UnAuthenticatedRoute exact path='/term' component={Term} />
              <UnAuthenticatedRoute exact path='/privacy' component={Privacy} />
              <UnAuthenticatedRoute exact path='/sitemap' component={Htmlsitemap} />
              <UnAuthenticatedRoute exact path='/business' component={SignUp} />
              <UnAuthenticatedRoute exact path='/recover' component={RecoverPassword} />
              <UnAuthenticatedRoute exact path='/reset-password' component={ResetPassword} />
              <UnAuthenticatedRoute exact path='/unsubscribe' component={Unsubscribe} />
              <UnAuthenticatedRoute exact path='/term' component={Term} />
              <UnAuthenticatedRoute exact path='/privacy' component={Privacy} />
              <UnAuthenticatedRoute exact path='/sitemap' component={Htmlsitemap} />
              <UnAuthenticatedRoute exact path='/delete-user' component={DeleteUser} />

              <UnAuthenticatedRoute
                exact
                path='/faq'
                render={(props) => <FAQ {...props} where={where} setWhere={setNewWhere} />}
              />
              <Route exact path='/blog' component={Blog} />
              <Route path='/blog/submit-blog' component={ArticleForm} />
              <Route path='/blog/:uniqueAddress' component={ArticleDetails} />

              <AuthenticatedRoute
                exact
                path='/blog-admin-panel'
                component={AdminPanelBlog}
                isUserEditor={userEditor}
              />
              <AuthenticatedRoute
                path='/blog-admin-panel/editor/:uniqueAddress'
                component={EditorArticle}
                isUserEditor={userEditor}
              />
              <AuthenticatedRoute
                path='/blog-admin-panel/article/:uniqueAddress'
                component={ArticleDetails}
                isUserEditor={userEditor}
              />

              <AuthenticatedRoute exact path='/business-home' component={BusinessHome} />

              <AuthenticatedRoute exact path='/signup-success' component={SignupSuccess} />
              <AuthenticatedRoute exact path='/deal-details' component={DealDetails} />

              <Route
                path='/businessinfo/profile/:id/:category?'
                component={withDeepLink(shareBussiness)}
              />

              <Route path='/businessinfo/deal/:deal_id' component={ShareDeals} />
              <UnAuthenticatedRoute
                exact
                path='/profile'
                render={(props) => (
                  <BusProfile
                    {...props}
                    setWhere={setNewWhere}
                    selectedProfileId={selectedProfileId}
                    selectedDealId={selectedDealId}
                    setSelectedDealId={setSelectedDealId}
                    setSelectedProfileId={setSelectedProfileId}
                  />
                )}
              />
              <UnAuthenticatedRoute
                exact
                path='/ddetail'
                render={(props) => (
                  <Ddetail
                    {...props}
                    setWhere={setNewWhere}
                    selectedDealId={selectedDealId}
                    selectedProfileId={selectedProfileId}
                    setSelectedProfileId={setSelectedProfileId}
                    setSelectedDealId={setSelectedDealId}
                  />
                )}
              />

              <AuthenticatedRoute exact path='/logout' component={Temp} />

              {/* <Route exact path="/404" component={NotFoundPage} /> */}
              <Route component={NotFoundPage} status={404}></Route>

              <AuthenticatedRoute path='/' component={BusinessHome} />
            </Switch>
            {location.pathname !== '/mobile-reset' && <Footer />}
          </main>
        </DealContext.Provider>
      </UserContext.Provider>
      {/*<RouteChangeTracker/>*/}
    </>
  );
};

export default withRouter(App);
