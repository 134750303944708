export function GroupSvg() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3708_865)'>
        <path
          d='M24 25.5C27.26 25.5 30.14 26.28 32.48 27.3C34.64 28.26 36 30.42 36 32.76V34C36 35.1 35.1 36 34 36H14C12.9 36 12 35.1 12 34V32.78C12 30.42 13.36 28.26 15.52 27.32C17.86 26.28 20.74 25.5 24 25.5ZM8 26C10.2 26 12 24.2 12 22C12 19.8 10.2 18 8 18C5.8 18 4 19.8 4 22C4 24.2 5.8 26 8 26ZM10.26 28.2C9.52 28.08 8.78 28 8 28C6.02 28 4.14 28.42 2.44 29.16C0.96 29.8 0 31.24 0 32.86V34C0 35.1 0.9 36 2 36H9V32.78C9 31.12 9.46 29.56 10.26 28.2ZM40 26C42.2 26 44 24.2 44 22C44 19.8 42.2 18 40 18C37.8 18 36 19.8 36 22C36 24.2 37.8 26 40 26ZM48 32.86C48 31.24 47.04 29.8 45.56 29.16C43.86 28.42 41.98 28 40 28C39.22 28 38.48 28.08 37.74 28.2C38.54 29.56 39 31.12 39 32.78V36H46C47.1 36 48 35.1 48 34V32.86ZM24 12C27.32 12 30 14.68 30 18C30 21.32 27.32 24 24 24C20.68 24 18 21.32 18 18C18 14.68 20.68 12 24 12Z'
          fill='url(#paint0_linear_3708_865)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3708_865'
          x1='-20.3265'
          y1='7.5'
          x2='49.1301'
          y2='10.589'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7B25' />
          <stop offset='1' stopColor='#FFB648' />
        </linearGradient>
        <clipPath id='clip0_3708_865'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MobileFriendly() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3708_873)'>
        <path
          d='M38.0002 2H18.0002C15.8002 2 14.0002 3.8 14.0002 6V10C14.0002 11.1 14.9002 12 16.0002 12C17.1002 12 18.0002 11.1 18.0002 10V8H38.0002V40H18.0002V38C18.0002 36.9 17.1002 36 16.0002 36C14.9002 36 14.0002 36.9 14.0002 38V42C14.0002 44.2 15.8002 46 18.0002 46H38.0002C40.2002 46 42.0002 44.2 42.0002 42V6C42.0002 3.8 40.2002 2 38.0002 2ZM14.0202 26.94L10.1802 23.1C9.48023 22.4 8.34023 22.4 7.64023 23.1C6.94023 23.8 6.94023 24.94 7.64023 25.64L12.5802 30.58C13.3602 31.36 14.6202 31.36 15.4002 30.58L27.1002 18.88C27.8002 18.18 27.8002 17.04 27.1002 16.34C26.4002 15.64 25.2602 15.64 24.5602 16.34L14.0202 26.94Z'
          fill='url(#paint0_linear_3708_873)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3708_873'
          x1='-7.65749'
          y1='-6.25'
          x2='42.9057'
          y2='-5.35856'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7B25' />
          <stop offset='1' stopColor='#FFB648' />
        </linearGradient>
        <clipPath id='clip0_3708_873'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Heart() {
  return (
    <svg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.2564 41.0986C25.7047 42.5074 23.316 42.5074 21.7643 41.0782L21.5397 40.874C10.821 31.1761 3.81805 24.8265 4.08346 16.9049C4.20596 13.434 5.98221 10.1061 8.86096 8.14612C14.251 4.47112 20.9068 6.18612 24.5001 10.392C28.0935 6.18612 34.7493 4.4507 40.1393 8.14612C43.018 10.1061 44.7943 13.434 44.9168 16.9049C45.2026 24.8265 38.1793 31.1761 27.4605 40.9149L27.2564 41.0986Z'
        fill='url(#paint0_linear_3708_881)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_3708_881'
          x1='-13.2221'
          y1='-0.631928'
          x2='45.9663'
          y2='0.860311'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7B25' />
          <stop offset='1' stopColor='#FFB648' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function LeaderBoard() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3708_886)'>
        <path
          d='M13 42H6C4.9 42 4 41.1 4 40V20C4 18.9 4.9 18 6 18H13C14.1 18 15 18.9 15 20V40C15 41.1 14.1 42 13 42ZM27.5 6H20.5C19.4 6 18.5 6.9 18.5 8V40C18.5 41.1 19.4 42 20.5 42H27.5C28.6 42 29.5 41.1 29.5 40V8C29.5 6.9 28.6 6 27.5 6ZM42 22H35C33.9 22 33 22.9 33 24V40C33 41.1 33.9 42 35 42H42C43.1 42 44 41.1 44 40V24C44 22.9 43.1 22 42 22Z'
          fill='url(#paint0_linear_3708_886)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3708_886'
          x1='-12.9388'
          y1='-0.750004'
          x2='45.0209'
          y2='0.682041'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7B25' />
          <stop offset='1' stopColor='#FFB648' />
        </linearGradient>
        <clipPath id='clip0_3708_886'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PlayIcon() {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.666503 13.9999C0.666504 21.3599 6.63984 27.3333 13.9998 27.3333C21.3598 27.3333 27.3332 21.3599 27.3332 13.9999C27.3332 6.63992 21.3598 0.666587 13.9998 0.666587C6.63984 0.666588 0.666503 6.63992 0.666503 13.9999ZM17.5332 14.4666L13.8132 18.1866C13.3865 18.6133 12.6665 18.3199 12.6665 17.7199L12.6665 10.2666C12.6665 9.66659 13.3865 9.37325 13.7998 9.79992L17.5198 13.5199C17.7865 13.7866 17.7865 14.2133 17.5332 14.4666Z'
        fill='url(#paint0_linear_3708_901)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_3708_901'
          x1='-4.3335'
          y1='38.6258'
          x2='-3.47417'
          y2='-0.0184724'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7B25' />
          <stop offset='1' stopColor='#FFB648' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function QuotesIcon() {
  return (
    <svg width='36' height='33' viewBox='0 0 36 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.4055 32.5438H0.00921656V17.0323C-0.0583716 14.8018 0.245776 12.7066 0.921659 10.7465C1.66513 8.78648 2.67896 7.06298 3.96313 5.57604C5.24731 4.08909 6.76805 2.8725 8.52535 1.92627C10.3502 0.912443 12.3103 0.270354 14.4055 0V6.69125C11.8372 7.56989 10.0799 8.88787 9.13364 10.6452C8.1874 12.3349 7.71429 14.4301 7.71429 16.9309H14.4055V32.5438ZM36 32.5438H21.6037V17.0323C21.5361 14.8018 21.8402 12.7066 22.5161 10.7465C23.2596 8.78648 24.2734 7.06298 25.5576 5.57604C26.8418 4.08909 28.3625 2.8725 30.1198 1.92627C31.9447 0.912443 33.9048 0.270354 36 0V6.69125C33.4316 7.56989 31.6743 8.88787 30.7281 10.6452C29.7819 12.3349 29.3088 14.4301 29.3088 16.9309H36V32.5438Z'
        fill='url(#paint0_linear_3708_1180)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_3708_1180'
          x1='-15.2449'
          y1='-6.10196'
          x2='36.9191'
          y2='-4.81881'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7B25' />
          <stop offset='1' stopColor='#FFB648' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function LensIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.7796 16.7216L13.4522 12.3943C14.5249 11.0865 15.1714 9.41143 15.1714 7.58571C15.1714 3.39796 11.7735 0 7.58571 0C3.39429 0 0 3.39796 0 7.58571C0 11.7735 3.39429 15.1714 7.58571 15.1714C9.41143 15.1714 11.0829 14.5286 12.3906 13.4559L16.718 17.7796C17.0118 18.0735 17.4857 18.0735 17.7796 17.7796C18.0735 17.4894 18.0735 17.0118 17.7796 16.7216ZM7.58571 13.6616C4.23184 13.6616 1.50612 10.9359 1.50612 7.58571C1.50612 4.23551 4.23184 1.50612 7.58571 1.50612C10.9359 1.50612 13.6653 4.23551 13.6653 7.58571C13.6653 10.9359 10.9359 13.6616 7.58571 13.6616Z'
        fill='#787878'
      />
    </svg>
  );
}
