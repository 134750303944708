import React from 'react';

import './Sections.css';

const waysData = [
  {
    id: 1,
    logo: './home/waysToSignUp/user.png',
    alt: "Register Your Business Online",
    title: (
      <>
        <p>Sign up & </p>
        <p>book a demo</p>
      </>
    ),
    content: (
      <span>
        <a href='/free-business-invitation'>Sign up your business for FREE</a>. Once your business is on our mobile app,
        <a
          href='https://calendly.com/momnpophub/rewards-demo'
          target='_blank'
          rel='noopener noreferrer'
        >
          book a virtual demo
        </a>{' '}
        or email us at contact@momnpophub.com.
      </span>
    ),
    buttons: (
      <div style={{display: 'flex'}}>
        <a
          href='https://play.google.com/store/apps/details?id=com.momnpophub'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src='./home/google-play-badge.png' className='googlePlayStore' alt='Listing App for Android Users' />
        </a>
        <a
          href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src='./home/apple-store-badge.png' className='applePlayStore' alt='Listing App for Iphone Users' />
        </a>
      </div>
    ),
  },
  {
    id: 2,
    logo: './home/waysToSignUp/card.png',
    title: 'Make your monthly payment',
    content: (
      <span>
        Learn more about costs in our <a href='#pricing-section'>pricing section</a>
      </span>
    ),
    alt: "Business Listing Price",
  },
  {
    id: 3,
    logo: './home/waysToSignUp/trophy.png',
    title: 'Setup your welcome and regular rewards',
    content: (
      <span>
        Read how to do it in our <a href='/blog'>article</a>
      </span>
    ),
    alt: "List your business and get rewards",
  },
  {
    id: 4,
    logo: './home/waysToSignUp/radio.png',
    title: 'Tell about the update',
    content: (
      <span>Brag to your customers for the new rewards program & start seeing the magic</span>
    ),
    alt: "Latest Listing Update",
  },
];

function WayToSignUpSection() {
  return (
    <div className='ways-section'>
      <h2 className='section-title'>Way to sign up for rewards program</h2>
      <div className='sign-up-crads'>
        {waysData?.map((card) => (
          <div className='sign-up-card' key={card?.id}>
            <div className='card-logo'>
              <img src={card?.logo} alt={card?.alt} />
            </div>
            <div>
              <h3 className='sign-up-card-title'>{card?.title}</h3>
              <div className='sign-up-card-content'>{card?.content}</div>
            </div>
            {card.buttons ? <div>{card.buttons}</div> : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default WayToSignUpSection;
