import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SignUpButton() {
  const history = useHistory();
  return (
    <button className='free-sign-btn' onClick={() => history.push('/free-business-invitation')}>
      Free sign up
    </button>
  );
}

export default SignUpButton;
